import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import SingleFood from './singleFood'
import WeekFoods from './weekFoods'
import WeekFoodsAi from './weekFoodsAi'

const MenuIndex = () => {
	const [selectedTab, setSelectedTab] = useState('singleFood')
	const {restaurants, orgFoods, mealServings, mealServingLogs} = useSelector(
    state => state
  )
  const { t } = useTranslation()
	if (!restaurants || !orgFoods || !mealServings || !mealServingLogs) {
    return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
	}
  return (<div>
  	<div>
	  	<button
	      type="button"
	      className={'button is-small' + (selectedTab === 'singleFood' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('singleFood')
	      }}
	    >
	      <Trans>Yksittäinen ruoka</Trans>
	    </button>
	    <button
	      type="button"
	      className={'button is-small' + (selectedTab === 'weekFoods' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('weekFoods')
	      }}
	    >
	      <Trans>Viikkoarvio</Trans>
	    </button>
		  <button
	      type="button"
	      className={'button is-small' + (selectedTab === 'weekFoodsAi' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('weekFoodsAi')
	      }}
	    >
	      <Trans>Koneoppimisarvio</Trans>
	    </button>
  	</div>
  	<div>
  	{ selectedTab === 'singleFood' && (<SingleFood />) }
  	{ selectedTab === 'weekFoods' && (<WeekFoods />) }
	  { selectedTab === 'weekFoodsAi' && (<WeekFoodsAi />) }
  	</div>
  </div>)
}

export default MenuIndex
