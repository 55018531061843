import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import Organization from './organization'
import Restaurants from './restaurants'
import Users from './users'

const MenuIndex = () => {
	const [selectedTab, setSelectedTab] = useState('users')
	const {restaurants, orgs, mealTypes, restaurantTypes, hukkaProfiles} = useSelector(
    state => state
  )
  const { t } = useTranslation()
	if (!restaurants || !orgs || !mealTypes || !restaurantTypes || !hukkaProfiles) {
    return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
	}
  return (<div>
  	<div>
	    <button
	      type="button"
	      className={'button is-small' + (selectedTab === 'users' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('users')
	      }}
	    >
	      <Trans>Käyttäjät</Trans>
	    </button>
	  	<button
	      type="button"
	      className={'button is-small' + (selectedTab === 'restaurants' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('restaurants')
	      }}
	    >
	      <Trans>Ravintolat</Trans>
	    </button>
	    <button
	      type="button"
	      className={'button is-small' + (selectedTab === 'organization' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('organization')
	      }}
	    >
	      <Trans>Organisaation asetukset</Trans>
	    </button>
  	</div>
  	<div>
  	{ selectedTab === 'organization' && (<Organization />) }
  	{ selectedTab === 'restaurants' && (<Restaurants />) }
  	{ selectedTab === 'users' && (<Users />) }
  	</div>
  </div>)
}

export default MenuIndex
