import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import Management from './management'
import Report from './report'

const Surveys = () => {
	const [selectedTab, setSelectedTab] = useState('management')
  return (<div>
  	<div>
	  	<button
	      type="button"
	      className={'button is-small' + (selectedTab === 'management' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('management')
	      }}
	    >
	      <Trans>Hallinta</Trans>
	    </button>
	    <button
	      type="button"
	      className={'button is-small' + (selectedTab === 'report' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('report')
	      }}
	    >
	      <Trans>Seuranta</Trans>
	    </button>
  	</div>
  	<div>
  	{ selectedTab === 'management' && (<Management />) }
  	{ selectedTab === 'report' && (<Report />) }
  	</div>
  </div>)
}

export default Surveys
