/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../actions'
import { Trans } from 'react-i18next'
import i18n from 'i18next'
import 'moment/locale/fi'
import 'moment/locale/sv'

const Nav = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [currentLang, setCurrentLang] = useState(i18n.language ? i18n.language.substring(0,2) : 'fi')
  const [langOpen, setLangOpen] = useState(false)
  const { loggedIn, isStaff, orgAdmin, rou:readOnlyUser } = useSelector(state => state.user) || { loggedIn: false, isStaff: false, orgAdmin: false, readOnlyUser: false }
  const dispatch = useDispatch()

  const changeLang = (lang) => {
    i18n.changeLanguage(lang)
    setCurrentLang(lang)
    setLangOpen(false)
    window.location.reload()
  }

  return (
    <div>
      <nav
        className="mainNav"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="mainNav__burger">
          <a
            role="button"
            className="mainNav__burger__link"
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setMobileOpen(!mobileOpen)}
            onKeyPress={() => setMobileOpen(!mobileOpen)}
            tabIndex={0}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div
          className={'mainNav__items' + (mobileOpen ? ' mainNav__items--open' : '')}
        >
          {loggedIn && readOnlyUser === 0 && (
            <Link
              className="mainNav__item"
              to="/"
              onClick={() => setMobileOpen(false)}
            >
              <span className="icon">
                <i className="fas fa-home fa-lg" />
              </span>
            </Link>
          )}
          {loggedIn && readOnlyUser === 0 && (
            <Link
              className="mainNav__item"
              to="/profile"
              onClick={() => setMobileOpen(false)}
            >
              <span className="icon">
                <i className="fas fa-user fa-lg" />
              </span>
            </Link>
          )}
          {loggedIn && readOnlyUser === 0 && (
            <Link
              className="mainNav__item"
              to="/info"
              onClick={() => setMobileOpen(false)}
            >
              <span className="icon">
                <i className="fas fa-info fa-lg" />
              </span>
            </Link>
          )}
          <div
            className="mainNav__select"
          >
            <a
              className="mainNav__item"
              onClick={() => setLangOpen(!langOpen)}
            >
              {currentLang}
            </a>
            <div
              className={'mainNav__select__options' + (langOpen ? ' mainNav__select__options--openMobile' : '')}
            >
              <a
                className={'mainNav__item mainNav__select__options__item' + (currentLang === 'fi' ? ' router-link-exact-active': '')}
                onClick={() => {
                  changeLang('fi')
                }}
                disabled={currentLang === 'fi'}
              >
                FI
              </a>
              <a
                className={'mainNav__item mainNav__select__options__item' + (currentLang === 'sv' ? ' router-link-exact-active': '')}
                onClick={() => {
                  changeLang('sv')
                }}
                disabled={currentLang === 'sv'}
              >
                SV
              </a>
              <a
                className={'mainNav__item mainNav__select__options__item' + (currentLang === 'en' ? ' router-link-exact-active': '')}
                onClick={() => {
                  changeLang('en')
                }}
                disabled={currentLang === 'en'}
              >
                EN
              </a>
              <a
                className={'mainNav__item mainNav__select__options__item' + (currentLang === 'es' ? ' router-link-exact-active': '')}
                onClick={() => {
                  changeLang('es')
                }}
                disabled={currentLang === 'es'}
              >
                ES
              </a>
            </div>
          </div>
          {loggedIn && readOnlyUser === 0 && (
            <Link
              className="mainNav__item"
              to="/addons"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Asiakasnäkymät</Trans>
            </Link>
          )}
          {loggedIn && readOnlyUser === 1 && (
            <Link
              className="mainNav__item"
              to="/magic-forest-2"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Taikametsä 2</Trans>
            </Link>
          )}
          {loggedIn && readOnlyUser === 1 && (
            <Link
              className="mainNav__item"
              to="/plate-waste"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Lautashävikki</Trans>
            </Link>
          )}
          {loggedIn && readOnlyUser === 1 && (
            <Link
              className="mainNav__item"
              to="/survey"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Kysely</Trans>
            </Link>
          )}
          {loggedIn && readOnlyUser === 0 && (
            <Link
              className="mainNav__item"
              to="/food-order-estimation"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Menekkiarvio</Trans>
            </Link>
          )}
          {loggedIn && (isStaff || orgAdmin) && (
            <Link
              className="mainNav__item"
              to="/menu"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Ruokalista</Trans>
            </Link>
          )}
          {!!isStaff && (
            <Link
              className="mainNav__item"
              to="/organizations"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Organisaatiot</Trans>
            </Link>
          )}
          {!!isStaff && (
            <Link
              className="mainNav__item"
              to="/waste-types"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Hävikkityypit</Trans>
            </Link>
          )}
          {loggedIn && readOnlyUser === 0 && (
            <Link
              className="mainNav__item"
              to="/reports"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Raportit</Trans>
            </Link>
          )}
          {loggedIn && (isStaff || orgAdmin) && (
            <Link
              className="mainNav__item"
              to="/profiles"
              onClick={() => setMobileOpen(false)}
            >
              <Trans>Profiilit</Trans>&<Trans>Ravintolat</Trans>
            </Link>
          )}
          {loggedIn && (
            <Link
              className="mainNav__item"
              onClick={() => {
                dispatch(logout())
                setMobileOpen(false)
                window.location.href = '/'
              }}
              to="/"
            >
              <Trans>Kirjaudu ulos</Trans>
            </Link>
          )}
        </div>
      </nav>
    </div>
  )
}

export default Nav