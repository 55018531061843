/* eslint-disable no-param-reassign */
import produce from 'immer'
import merge from 'lodash/merge'
import {
  LOGIN_REQUEST_SUCCESS,
  TOKEN_LOGIN,
  LOGOUT,
  FETCH_ALL_DATA_SUCCESS,
  UPDATE_ADD_WASTE_FORM,
  RENEW_MEALSERVINGS,
  UPDATE_MEALSERVINGS,
  RENEW_MEALSERVINGLOGS,
  ADD_FOODPRICE,
  UPDATE_MEALSERVING_LOGS,
  UPDATE_RESTAURANTS,
  REMOVE_FROM_RESTAURANTS,
  UPDATE_HUKKA_PROFILES,
  REMOVE_FROM_HUKKAPROFILES,
  EMPTY_ADD_WASTE_FORM,
  REMOVE_MEALSERVING_LOG,
  HYDRATE_USER,
  REMOVE_MEALSERVING,
  UPDATE_GENERICSURVEYLINES,
  UPDATE_GENERICSURVEYS,
  UPDATE_AVGWASTE,
  UPDATE_SCORES,
  SET_MENUORDERESTIMATE,
  SET_WEEKSERVINGSTATS,
  SET_YEARSERVINGSTATS,
  SET_FOODSTATS,
  UPDATE_ORGFOODS,
  RENEW_ORGFOODS,
  SET_ORGS,
  SET_RESTAURANTS,
  SET_SERVINGPREDICTIONS,
  SET_SERVINGPREDICTIONSTATUSES,
  UPDATE_FORMFIELDTEMPLATES,
  UPDATE_FORMTEMPLATES,
  UPDATE_FORMS,
  UPDATE_FIELDORDERS,
  UPDATE_FORMROWS,
  UPDATE_FORMFIELDS,  
  UPDATE_MONITORINGPLANS,
  ADD_FOODCOURSEDEFAULT,
  UPDATE_FOODCOURSEDEFAULT
} from '../actions'

export const initialState = {
  user: {
    loggedIn: false,
    token: null
  },
  restaurants: [],
  mealServings: [],
  mealTypes: [],
  wasteTypes: [],
  mealServingLogs: [],
  addWasteForm: {},
  servingPredictions: {},
  servingPredictionStatuses: {},
  formFieldTemplates: [],
  formTemplates: [],
  fieldOrders: [],
  forms: [],
  formRows: [],
  formFields: [],
  foodCourseDefaults: []
}

export default function reducer(state = initialState, action) {
  const { payload } = action
  return produce(state, draft => {
    switch (action.type) {
      case LOGOUT:
        draft = initialState
        break

      case LOGIN_REQUEST_SUCCESS: {
        const { userInfo, token } = payload

        draft.user = { ...userInfo, loggedIn: true, token }
        break
      }
      case TOKEN_LOGIN: {
        const { token } = payload

        draft.user = { token, loggedIn: true, rou: 1 }
        break
      }
      case FETCH_ALL_DATA_SUCCESS:
        draft = { ...draft, ...payload }
        break
      case UPDATE_ADD_WASTE_FORM: {
        const [target, value] = payload
        draft.addWasteForm[target] = value
        break
      }
      case EMPTY_ADD_WASTE_FORM:
        delete draft.addWasteForm.preparedAmountKg
        delete draft.addWasteForm.wasteKg
        delete draft.addWasteForm.selectedFood
        delete draft.addWasteForm.selectedWasteType

        break
      case RENEW_MEALSERVINGS:
        draft.mealServings = payload
        break
      case UPDATE_MEALSERVINGS:
        draft.mealServings = draft.mealServings
          .filter(ms => ms.id !== payload.id)
          .concat([payload])
        break
      case REMOVE_MEALSERVING:
        draft.mealServings = draft.mealServings.filter(ms => ms.id !== payload)
        break
      case RENEW_MEALSERVINGLOGS:
        draft.mealServingLogs = payload
        break
      case ADD_FOODPRICE:
        draft.foodPrices.push(payload)
        break
      case UPDATE_MEALSERVING_LOGS:
        draft.mealServingLogs = draft.mealServingLogs
          .filter(msl => msl.id !== payload.id)
          .concat([payload])
        break
      case UPDATE_RESTAURANTS: {
        draft.restaurants = draft.restaurants
          .filter(r => r.id !== payload.id)
          .concat([payload])
        break
      }
      case REMOVE_FROM_RESTAURANTS:
        draft.restaurants = draft.restaurants.filter(r => r.id !== payload)
        break
      case REMOVE_FROM_HUKKAPROFILES:
        draft.hukkaProfiles = draft.hukkaProfiles.filter(
          hp => hp.id !== payload
        )
        break
      case UPDATE_HUKKA_PROFILES:
        draft.hukkaProfiles = draft.hukkaProfiles
          .filter(hp => hp.id !== payload.id)
          .concat([payload])
        break
      case UPDATE_GENERICSURVEYS:
        draft.genericSurveys = draft.genericSurveys
          .filter(gs => gs.id !== payload.id)
          .concat([payload])
        break
      case UPDATE_GENERICSURVEYLINES:
        draft.genericSurveyLines = draft.genericSurveyLines
          .filter(gsl => gsl.id !== payload.id)
          .concat([payload])
        break
      case REMOVE_MEALSERVING_LOG:
        draft.mealServingLogs = draft.mealServingLogs.filter(
          msl => msl.id !== payload
        )
        break
      case UPDATE_AVGWASTE:
        draft.avgWaste = payload
        break
      case UPDATE_SCORES:
        draft.scores = payload
        break
      case SET_MENUORDERESTIMATE:
        draft.menuOrderEstimate = payload
        break
      case SET_WEEKSERVINGSTATS:
        draft.weekServingStats = payload
        break
      case SET_YEARSERVINGSTATS:
        draft.yearServingStats = payload
        break
        case SET_FOODSTATS:
          draft.foodStats = payload
          break
      case UPDATE_ORGFOODS:
        draft.orgFoods = draft.orgFoods
          .filter(food => food.id !== payload.id)
          .concat([payload])
        break
      case RENEW_ORGFOODS:
        draft.orgFoods = payload
        break
      case SET_ORGS:
          draft.orgs = payload
          break
      case SET_RESTAURANTS:
        draft.restaurants = payload
        break
      case SET_SERVINGPREDICTIONS:
        Object.keys(payload).forEach(function (key) {
          draft.servingPredictions[key.toLowerCase().replaceAll('-', '')] = payload[key]
        })
        break
      case SET_SERVINGPREDICTIONSTATUSES:
        draft.servingPredictionStatuses[payload.id] = payload.status
        break
      case UPDATE_FORMFIELDTEMPLATES:
        draft.formFieldTemplates = payload
        break
      case UPDATE_FORMTEMPLATES:
        draft.formTemplates = payload
        break
      case UPDATE_FORMS:
        draft.forms = payload
        break
      case UPDATE_FIELDORDERS:
        draft.fieldOrders = payload
        break
      case UPDATE_FORMROWS:
        draft.formRows = payload
        break
      case UPDATE_FORMFIELDS:
        draft.formFields = payload
        break
      case UPDATE_MONITORINGPLANS:
        draft.monitoringPlans = payload
        break
      case HYDRATE_USER:
        draft.user = merge(draft.user, payload)
        break
      case ADD_FOODCOURSEDEFAULT:
        draft.foodCourseDefaults.push(payload)
        break
      case UPDATE_FOODCOURSEDEFAULT:
        draft.foodCourseDefaults = draft.foodCourseDefaults
          .filter(fcd => fcd.id !== payload.id)
          .concat([payload])
        break

      default:
        return draft
    }

    return draft
  })
}
