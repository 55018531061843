import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import Menu from './menu'
import Foods from './foods'

const MenuIndex = () => {
	const [selectedTab, setSelectedTab] = useState('menu')
	const {orgFoods, foodPrices, mealServings, mealServingLogs, restaurants, mealTypes, hukkaProfiles} = useSelector(
    state => state
  )
  const { t } = useTranslation()
	if (!orgFoods || !foodPrices || !mealServings || !mealServingLogs || !restaurants || !mealTypes || !hukkaProfiles) {
    return (<p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>)
	}
  
  return (<div>
  	<div>
	  	<button
	      type="button"
	      className={'button is-small' + (selectedTab === 'menu' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('menu')
	      }}
	    >
	      <Trans>Ruokalista</Trans>
	    </button>
	    <button
	      type="button"
	      className={'button is-small' + (selectedTab === 'foods' ? ' is-primary-background' : '')}
	      style={{margin: '5px'}}
	      onClick={() => {
	        setSelectedTab('foods')
	      }}
	    >
	      <Trans>Ruokahinnasto</Trans>
	    </button>
  	</div>
  	<div>
  	{ selectedTab === 'menu' && (<Menu />) }
  	{ selectedTab === 'foods' && (<Foods />) }
  	</div>
  </div>)
}

export default MenuIndex
