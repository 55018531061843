import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment'
import { get, ORDERESTIMATE_URL } from '../../utility/api'
import HukkaSelect from '../hukkaSelect'

const FoodOrderEstimation = () => {
  const {
    restaurants,
    orgFoods
  } = useSelector(state => state)
  const { t } = useTranslation()
  const [selectedRestaurant, setSelectedRestaurant] = useState(null)
  const [selectedFood, setSelectedFood] = useState(null)
  const [customerAmount, setCustomerAmount] = useState(0)
  const [estimateData, setEstimateData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const restaurantOptions =
    restaurants &&
    restaurants.slice().sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(r => ({
      value: r.id,
      label: r.name,
    }))

  const getFoodOptions = () => {
    const filteredFoods = orgFoods.filter(f => f.name !== null && (Object.keys(f.canon).length === 0 || f.id === f.canon.food))
    return filteredFoods.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    .map(f => ({
      value: f.id,
      label: f.name
    }))
  }

  const fetchEstimateData = async (selectedType, selectedValue) => {
    const food = selectedType === 'food' ? selectedValue : selectedFood
    const restaurant = selectedType === 'restaurant' ? selectedValue : selectedRestaurant
    if (food !== null && restaurant !== null) {
      const resp = await get(ORDERESTIMATE_URL + restaurant.value + '/' + food.value)
      if (resp.status === 200) {
        setEstimateData(resp.data)
        setError(null)
        setLoading(false)
      } else {
        setError('Ei arviota saatavilla!')
        setLoading(false)
      }
    }
  }

  return (
    <div>
      <h1 className="title is-1 is-size-3-mobile is-uppercase"><Trans>Menekkiarvio</Trans></h1>

      <div style={{padding: '10px'}}>
        <div className="control">
          <div className="select is-large is-fullwidth hukkaselect">
            <HukkaSelect
              options={restaurantOptions}
              value={selectedRestaurant}
              placeholder={t('Valitse ravintola')}
              onChange={e => {
                setSelectedRestaurant(e)
                if (restaurants.find(r => r.id === e.value)) {
                  if (restaurants.find(r => r.id === e.value).defaultCustomerAmount) {
                    setCustomerAmount(parseInt(restaurants.find(r => r.id === e.value).defaultCustomerAmount))
                  } else {
                    setCustomerAmount(1)
                  }
                } else {
                  setCustomerAmount(1)
                }
                setLoading(true)
                fetchEstimateData('restaurant', e)
              }}
            />
          </div>
        </div>
        {selectedRestaurant && (
          <div className="field">
            <div className="control">
              <div className="select is-large is-fullwidth hukkaselect">
                <HukkaSelect
                  options={getFoodOptions()}
                  placeholder={t('Valitse ruoka')}
                  value={selectedFood}
                  onChange={e => {
                    setSelectedFood(e)
                    setLoading(true)
                    fetchEstimateData('food', e)
  
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {selectedRestaurant && (
          <div>
            <div style={{ textAlign: 'left' }}><Trans>Asiakasmäärä</Trans></div>
            <div
              className="field"
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <input
                className="is-large has-text-primary is-expanded"
                placeholder="Asiakasmäärä"
                style={{ fontSize: '16px', flex: 1 }}
                type="number"
                value={customerAmount}
                onChange={e => {
                  setCustomerAmount(e.target.value)
                }}
              />
            </div>
          </div>
        )}
        {loading === false && estimateData !== null && error === null && (
          <div>
            {estimateData.servings && estimateData.servings > 2 && (
              <>
                <br />
                <p className="title is-3 is-size-4-mobile" style={{marginBottom: '0', lineHeight: '2'}}>{t('Menekkiarvio')} <span className="text-secondary-color">{customerAmount}</span> {t('asiakkaalle')}: <span style={{margin: '0'}} className="text-secondary-color title is-1 is-size-2-mobile">{estimateData ? (estimateData.avgCustomers ? (parseFloat(estimateData.estimate) / parseFloat(estimateData.avgCustomers) * customerAmount / 1000).toFixed(1) : 0) : '-'}kg</span> ({t('sisältää 5% puskurin')})</p>
                <p className="title is-3 is-size-4-mobile" style={{marginBottom: '0', lineHeight: '2'}}>{t('Arvio perustuu')} <span>{estimateData.servings}</span> {t('aikaisempaan tarjoilukertaan')} (<span>{estimateData ? (estimateData.avgCustomers ? (parseFloat(estimateData.estimate) / parseFloat(estimateData.avgCustomers) / 1000).toFixed(2) : 0) : '-'}kg</span> {t('per asiakas')})</p>
                <br />
                <button
                  type="button"
                  className="button is-primary is-small"
                  onClick={() => {
                    window.location.href = '/waste-report?restaurants=' + selectedRestaurant.value + '&startDate=' + moment(estimateData.firstServing).format('DD.MM.YYYY') + '&endDate=' + moment(estimateData.lastServing).format('DD.MM.YYYY') + '&scope=foods&foods=' + selectedFood.value
                  }}
                >
                  <Trans>Näytä kattaukset</Trans>
                </button>
              </>
            )}
            {estimateData.servings && estimateData.servings <= 2 && (
              <>
                <div>
                  <p className="title is-3 is-size-4-mobile text-secondary-color">{t('Ruualla alle kolme tarjoilukertaa')}!</p>
                  <button
                    type="button"
                    className="button is-primary is-small"
                    onClick={() => {
                      window.location.href = '/waste-report?restaurants=' + selectedRestaurant.value + '&startDate=' + moment(estimateData.firstServing).format('DD.MM.YYYY') + '&endDate=' + moment(estimateData.lastServing).format('DD.MM.YYYY') + '&scope=foods&foods=' + selectedFood.value
                    }}
                  >
                    <Trans>Näytä kattaukset</Trans>
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        {loading === false && estimateData !== null && error === null && !estimateData.servings && (
          <p className="title is-3 is-size-4-mobile text-secondary-color">{t('Ruualla ei ole tarjoilukertoja tässä ravintolassa')}!</p>
        )}
        {loading === true && selectedRestaurant && selectedFood && (
          <p className="is-size-2 is-size-3-mobile is-uppercase flashAnimation" style={{marginTop: '20px'}}>{t('Ladataan')}...</p>
        )}
        {loading === false && error !== null && (
          <p className="title is-3 is-size-4-mobile text-secondary-color">{error}</p>
        )}
      </div>
    </div>
  )
}

export default FoodOrderEstimation
